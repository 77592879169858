























import { Vue, Component, Prop } from "vue-property-decorator";
import { AlertDialogProperties } from '@/components/shared/AlertDialogView.vue';
import { HomeProperties } from '@/components/home/HomeView.vue';
import EventManager from '@/utilities/EventManager';
import TransferRequestService from '@/services/TransferRequestService';
import CompanyService from '@/services/CompanyService';
import ExceptionUtil from '@/utilities/ExceptionUtil';
import ResponseUtil from '@/utilities/ResponseUtil';

const MobileTransferDetailView = () => import("./MobileTransferDetailView.vue");
const MobileTransferSummaryView = () => import("./MobileTransferSummaryView.vue");

class MobileTransferInfoProperties {
    request: any = {};
    currency: any = {};
    details: any[] = [];
    dateTimeFormat: string = null;
    events = new EventManager();
}

export { MobileTransferInfoProperties };

@Component({
	metaInfo: {
		link: [{ rel: "stylesheet", href: "/home-transfer-info.css" }]
	},
    components: { MobileTransferDetailView, MobileTransferSummaryView },
    data: () => ({
        loading: false,
        saving: false
    })
})
export default class MobileTransferInfoView extends Vue {
    private properties = new MobileTransferInfoProperties();
    @Prop() private home: HomeProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    private companyService = new CompanyService();
    private transferRequestService = new TransferRequestService();

    public created() {
        this.load();
        this.properties.events.subscribe('cancel', this.cancelDialog);
        this.home.events.subscribe('alert-dialog-clicked', this.alertDialogClicked);
    }

    public destroyed() {
        this.properties.events.remove('cancel', this.cancelDialog);
        this.home.events.remove('alert-dialog-clicked', this.alertDialogClicked);
    }

    public async loadCurrency() {
        const r = await this.companyService.currency();
        this.properties.currency = r.data;
    }

    public async loadTransferRequest() {
        const uniqueNo = this.$route.params.uniqueNo;
        const r = await this.transferRequestService.current({
            uniqueNo,
            loadTransferTo: true
        });
        if (r.data.transferRequests.length === 0) {
            throw new Error(this.$t("message.record-not-found").toString());
        }

        const request = r.data.transferRequests[0];
        this.properties.request = request;
        this.properties.dateTimeFormat = ResponseUtil.getDateTimeFormat(r);
        await this.properties.events.fire("load-details");
    }

    public async load() {
        this.$data.loading = true;

        try {
            await this.loadCurrency();
            await this.loadTransferRequest();
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.tag = { command: "load-error", redirect: "/payment-payment" };
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.loading = false;
        }
    }

    public async cancel() {
        if (this.$data.saving) {
            return;
        }
        this.$data.saving = true;

        try {
            const request = this.properties.request;
            await this.transferRequestService.cancel(request);
            this.load();
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.saving = false;
        }
    }

    public cancelDialog() {
        const t = this.$t("title.cancel-transfer");
        const m = this.$t("message.cancel-transfer");
        AlertDialogProperties.standard(this.alertDialog, t, m);
        this.alertDialog.buttons = [this.$t("text.yes"), this.$t("text.no")];
        this.alertDialog.primaryButton = this.$t("text.yes");
        this.alertDialog.tag = { command: "cancel-transfer" };
        this.alertDialog.visible = true;
    }

    public alertDialogClicked(button: string) {
        const tag = this.alertDialog.tag;
        if (tag) {
            if (tag.command === 'cancel-transfer' && button === this.$t('text.yes')) {
                this.cancel();
            }
        }
    }
}
